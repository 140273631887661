@media (max-width: 768px) {
  .unlogged--confirmation-user-component > .fr-bottom{
    grid-template-columns: 5% 90% 5%;
  }

  .unlogged--confirmation-user-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .title{
    font-size: 30px;
  }

  .unlogged--confirmation-user-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .text{
    font-size: 14px;
  }
}