@import url('../../united/united.css');
@import url('../../../../components/ui/unlogged_cn/sessions_new_component/sessions_new_component.css');
@import url('../../../../components/ui/unlogged_cn/sessions_new_component/sessions_new_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/registrations_new_component/registrations_new_component.css');
@import url('../../../../components/ui/unlogged_cn/registrations_new_component/registrations_new_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/passwords_new_component/passwords_new_component.css');
@import url('../../../../components/ui/unlogged_cn/passwords_new_component/passwords_new_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/confirmations_new_component/confirmations_new_component.css');
@import url('../../../../components/ui/unlogged_cn/confirmations_new_component/confirmations_new_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/passwords_edit_component/passwords_edit_component.css');

@import url('../../../../components/ui/unlogged_cn/bar_top_component/bar_top_component.css');
@import url('../../../../components/ui/unlogged_cn/bar_top_component/bar_top_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/bar_top_dropdown_component/bar_top_dropdown_component.css');
@import url('../../../../components/ui/unlogged_cn/bar_top_dropdown_component/bar_top_dropdown_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/confirmation_user_component/confirmation_user_component.css');
@import url('../../../../components/ui/unlogged_cn/confirmation_user_component/confirmation_user_mobile_component.css');

@import url('../../../../components/ui/unlogged_cn/recover_user_component/recover_user_component.css');

@import url('../../../../components/ui/unlogged_cn/email_confirmed_component/email_confirmed_component.css');
@import url('../../../../components/ui/unlogged_cn/email_confirmed_component/email_confirmed_mobile_component.css');
:root{
  --unlogged-lines-icon-string: url("data:image/svg+xml,%3Csvg width='117' height='154' viewBox='0 0 117 154' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg opacity='0.15'%3E%3Cpath d='M-11 18.6667C-11 8.35735 -2.64265 0 7.66667 0H98.3333C108.643 0 117 8.35735 117 18.6667V18.6667C117 28.976 108.643 37.3333 98.3333 37.3333H7.66666C-2.64265 37.3333 -11 28.976 -11 18.6667V18.6667Z' fill='%23FFC107'/%3E%3Cpath d='M-11 77.3322C-11 67.0229 -2.64265 58.6655 7.66667 58.6655H82.3334C92.6427 58.6655 101 67.0229 101 77.3322V77.3322C101 87.6415 92.6427 95.9989 82.3333 95.9989H7.66666C-2.64266 95.9989 -11 87.6415 -11 77.3322V77.3322Z' fill='%234A2EF1'/%3E%3Cpath d='M-11 134.934C-11 124.624 -2.64265 116.267 7.66667 116.267H55.6667C65.976 116.267 74.3333 124.624 74.3333 134.934V134.934C74.3333 145.243 65.976 153.6 55.6667 153.6H7.66665C-2.64267 153.6 -11 145.243 -11 134.934V134.934Z' fill='%23E0DBFF'/%3E%3C/g%3E%3C/svg%3E%0A");
}