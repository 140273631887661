.united--notify-dark-component{
  grid-template-columns: 20px auto 20px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  color: var(--white3-color);
  border: 1px solid var(--black4-color);
  background-color: var(--black3-color);
  width: 300px;
  column-gap: 10px;
  border-radius: 10px;
  padding: 15px 10px;
  box-shadow: 0 8px 24px #010409;
  display: none;
  z-index: 30;
}

.united--notify-dark-component-active{
  display: grid;
}

.united--notify-dark-component > .cl-left > .icon{
  background-image: var(--done3-icon);
  height: 15px;
  width: 15px;
}

.united--notify-dark-component > .cl-center{
  font-size: 14px;
}

.united--notify-dark-component > .cl-center > .title{
  padding-bottom: 10px;
}

.united--notify-dark-component > .cl-right > .btn{
  width: 15px;
  height: 15px;
  background-image: var(--close-icon-string);
}
