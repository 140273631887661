.unlogged--bar-top-component{
  grid-template-columns: 50px 230px auto 115px 115px 50px;
  height: var(--unlogged-bar-top-height);
}

.unlogged--bar-top-component > .cl-event-dropdown{
  display: none;
}

.unlogged--bar-top-component > .cl-event > .btn{
  color: var(--light-black-color);
  padding: 13px 29px;
  border-radius: 5px;
  text-decoration: none;
}

.unlogged--bar-top-component > .cl-event > .btn:hover{
  text-decoration: 1px solid var(--light-black-color);
}


.unlogged--bar-top-component > .cl-event > .btn-signup{
  background-color: var(--violet-color);
  color: var(--white-color);
}

.unlogged--bar-top-component > .cl-event > .btn-signup:hover{
  text-decoration: 1px solid var(--white-color);
}

.unlogged--bar-top-component > .cl-logo > .logo{
  height: 30px;
  display: block;
  background-image: var(--logo-icon);
  background-size: 80%;
}
