@media (max-width: 768px) {
  .ui--united-cn--navbar-notify-dropdown-component > .content-fr{
    padding: 0 16px 0 16px;
  }

  .ui--united-cn--navbar-notify-dropdown-component > .dropdown-fr{
    --local-top-offset: calc(var(--navbar-height) + 5px);
    top: var(--local-top-offset);
    right: 0;
    width: 100vw;
    max-height: calc(100vh - var(--local-top-offset) - 100px);
  }

  .ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
  > .fr-tab-contents{
    max-height: calc(100vh - var(--local-top-offset) - 100px - 85px - 20px);
  }

  .ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
  > .fr-tab-contents > .item > .fr-list{
    --local-item-height: calc(100vh - var(--local-top-offset) - 100px - 85px - 20px);
    max-height: calc( var(--local-item-height) - var(--local-events-height))
  }
}