.united--navbar-processing-dropdown-component{
  width: 100%;
  height: 100%;
  position: relative;
  --local-image-width: 15px;
}

.united--navbar-processing-dropdown-component > .content-fr{
  width: 100%;
  height: 100%;
  grid-template-columns: var(--local-image-width);
  padding: 0 16px;
}

.united--navbar-processing-dropdown-component-active > .content-fr{
  background-color: var(--light-yellow-color);
}

.united--navbar-processing-dropdown-component > .content-fr
> .cl-text{
  color: var(--medium-grey-color);
  font-size: 15px;
}

.united--navbar-processing-dropdown-component > .content-fr
> .cl-image > .image{
  width: var(--local-image-width);
  height: 15px;
  /* background-image: var(--done1-icon-string); */
}

.united--navbar-processing-dropdown-component-processing > .content-fr
> .cl-image > .image{
  background-image: var(--loading1-voice-icon);
}
