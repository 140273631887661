.ui--united-cn--navbar-user-dropdown-component{
  width: 100%;
  height: 100%;
  position: relative;
  --local-arrow-width: 7px;
}

.ui--united-cn--navbar-user-dropdown-component > .content-fr{
  width: 100%;
  height: 100%;
  grid-template-columns: auto auto var(--local-arrow-width);
  column-gap: 15px;
  padding: 0 16px 0 8px;
}

.ui--united-cn--navbar-user-dropdown-component-active > .content-fr{
  background: var(--light9-grey-color);
}

.ui--united-cn--navbar-user-dropdown-component > .content-fr:hover{
  background: var(--light9-grey-color);
}

.ui--united-cn--navbar-user-dropdown-component > .content-fr
> .cl-avatar > .avatar{
  background-color: var(--light-yellow-color);
  color: var(--yellow-color);
  line-height: 2.9;
  font-size: 14px;
}

.ui--united-cn--navbar-user-dropdown-component > .content-fr
> .cl-text{
  color: var(--light-black-color);
}

.ui--united-cn--navbar-user-dropdown-component > .content-fr
> .cl-image > .image{
  width: var(--local-arrow-width);
  height: 5px;
  background-image: var(--arrow-down-icon-string);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-user-dropdown-component-active > .content-fr
> .cl-image > .image{
  transform: rotate(180deg);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-user-dropdown-component > .dropdown-fr{
  position: absolute;
  top: 115%;
  right: 0;
  width: 110%;
  z-index: 10;
  display: none;
  padding: 0 0 10px 0;
}

.ui--united-cn--navbar-user-dropdown-component-active > .dropdown-fr{
  display: block;
}

.ui--united-cn--navbar-user-dropdown-component > .dropdown-fr
> .items > .item{
  grid-template-columns: 24px auto;
  padding: 7px 8px 8px 12px;
  column-gap: 10px;
  margin: 5px 0 0 0;
  text-decoration: none;
}

.ui--united-cn--navbar-user-dropdown-component > .dropdown-fr
> .items > .item:hover{
  background: var(--light-grey-color);
}

.ui--united-cn--navbar-user-dropdown-component > .dropdown-fr
> .items > .item{
  color: var(--light-black-color);
  white-space: nowrap;
  overflow: hidden;
  display: block;
}
