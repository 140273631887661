@media (max-width: 768px) {
  .united--navbar-component{
    grid-template-columns: auto min-content;
  }
  .united--navbar-component > .cl-right{
    grid-template-columns: 0px 0px min-content min-content 15px;
  }

  .united--navbar-component > .cl-right
  > .cl-library{
    display: none;
  }

  .united--navbar-component > .cl-right
  > .cl-user-dropdown > .burger-fr{
    display: block;
    height: 32px;
    width: 32px;
    background-image: var(--burger-icon-string);
  }
}