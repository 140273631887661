.unlogged--email-confirmed-component{
  height: calc(100vh);
  grid-template-rows: var(--unlogged-bar-top-height) calc(100vh - var(--unlogged-bar-top-height));
  background: linear-gradient(180deg,hsla(0,0%,100%,0), var(--light2-yellow-color) 80%);
}

.unlogged--email-confirmed-component > .fr-bottom{
  grid-template-columns: 0.5fr 2fr 0.5fr;
  grid-template-rows: 0.5fr 4fr 0.5fr;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content{
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  grid-column: 2;
  grid-row: 2;
  background-color: var(--white-color);
  background-image: var(--unlogged-lines-icon-string);
  border-radius: 15px;
  background-position: 0 10%;
  grid-template-rows: 1fr auto 1fr;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form{
  grid-row: 2;
  /* grid-template-columns: 1fr 1fr; */
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-left{
  padding: 0 60px;
  border-right: 1px solid var(--light-grey-color);
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-left
> .title{
  color: var(--light-black-color);
  font-size: 50px;
  font-family: "RobotoBlack";
  padding: 0 0 20px 0;
  background: linear-gradient(90.72deg, #FFC107 30%, #4A2EF1 92.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right{
  padding: 0 30px;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form{
  min-width: 300px;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field{
  padding: 0 0 20px 0;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fr-field-remember{
  grid-template-columns: min-content auto;
  column-gap: 20px;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fr-field-remember > .cl-left > .check-box{
  width: 20px;
  height: 20px;
  /* background-image: var(--done-icon); */
  border: 1px solid var(--light-grey-color);
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fr-field-remember > .cl-right{
  color: var(--light-black-color);
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .fr-field-remember > .cl-left > .check-box-active{
  background-image: var(--done4-icon);
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .field > .txt{
  border: 1px solid var(--light12-grey-color);
  border-radius: 5px;
  width: 100%;
  display: block;
  padding: 16px 0 14px 0;
}

.unlogged--email-confirmed-component > .fr-bottom
> .fr-content > .fr-text-and-form > .cl-right
> .form > .actions > .btn{
  padding: 17px 0;
  border-radius: 100px;
  width: 100%;
  font-size: 18px;
  background: var(--violet-color);
  color: var(--white-color);
}

