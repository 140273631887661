.ui--united-cn--navbar-upload-dropdown-component{
  width: 100%;
  height: 100%;
  position: relative;
  --local-arrow-width: 7px;
}

.ui--united-cn--navbar-upload-dropdown-component > .content-fr{
  height: 100%;
  grid-template-columns: auto var(--local-arrow-width);
  column-gap: 10px;
  padding: 0 16px;
}

.ui--united-cn--navbar-upload-dropdown-component-active > .content-fr{
  background: var(--light9-grey-color);
}

.ui--united-cn--navbar-upload-dropdown-component > .content-fr:hover{
  background: var(--light9-grey-color);
}

.ui--united-cn--navbar-upload-dropdown-component
> .content-fr > .cl-mobile{
  display: none;
}

.ui--united-cn--navbar-upload-dropdown-component > .content-fr
> .cl-text{
  color: var(--medium-black-color);
}

.ui--united-cn--navbar-upload-dropdown-component > .content-fr
> .cl-image > .image{
  width: var(--local-arrow-width);
  height: 5px;
  background-image: var(--arrow-down2-icon-string);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-upload-dropdown-component-active > .content-fr
> .cl-image > .image{
  transform: rotate(180deg);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr{
  position: absolute;
  top: 105%;
  left: 0;
  z-index: 10;
  display: none;
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .dropdown-fr-bg{
  display: none;
}


.ui--united-cn--navbar-upload-dropdown-component-active > .dropdown-fr{
  display: block;
}
/* --- */

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items{
  padding: 10px;
  grid-template-columns: min-content min-content min-content;
  column-gap: 10px;
  row-gap: 10px;
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload{
  /* 270x90 */
  background-color: var(--light2-violet-color);
}
.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload:hover{
}
.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload > .image{
  background-image: var(--girls-icon);
  height: 60px;
  width: 100px;
  row-gap: 10px;
  position: relative;
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload > .image > .play-icon{
  background-image: var(--play2-icon);
  width: 22px;
  height: 22px;
  position: absolute;
  top: calc(50% - 11px);
  left: calc(50% - 11px);
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload > .text{
  padding-left: 20px;
  padding-right: 20px;
  font-size: 15px;
  color: var(--light-black-color);
  line-height: 3.2;
  white-space: nowrap;
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload > .text-success{
  color: var(--green-color);
}

.ui--united-cn--navbar-upload-dropdown-component > .dropdown-fr
> .items > .fr-upload-image > .image{
  background-image: var(--women-things-icon);
}

