@media (max-width: 768px) {
  .unlogged--email-confirmed-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-left{
    padding: 0;
  }
  .unlogged--email-confirmed-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-right
  > .form{
    min-width: auto;
    width: 100%;
  }

  .unlogged--email-confirmed-component > .fr-bottom{
    grid-template-columns: 5% 90% 5%;
    grid-template-rows: 0.5fr 4fr 0.5fr;
  }

  .unlogged--email-confirmed-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-left
  > .title{
    font-size: 40px;
  }
}