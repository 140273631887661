.united--navbar-component{
  height: var(--navbar-height);
  margin-bottom: var(--navbar-margin);
  grid-template-columns: 1fr 3.5fr;
  position: relative;
  padding: 0;
  z-index: 10;
}

.united--navbar-component > .cl > a.logo{
  width: 200px;
  height: 100%;
  display: block;
  background-image: var(--logo-icon-string2);
  background-size: 75%;
  background-position: 0 50%;
}

.united--navbar-component > .cl-right{
  grid-template-columns: 120px min-content auto min-content min-content 20px;
  position: relative;
}

.united--navbar-component > .cl-right
> .cl-library{
  padding: 0 16px;
}

.united--navbar-component > .cl-right
> .cl-library > .lnk{
  color: var(--yellow-color);
  font-size: 14px;
  height: 100%;
  /* text-decoration: none; */
  /* line-height: 50px; */
}

.united--navbar-component > .cl-right
> .cl-user-dropdown > .burger-fr{
  display: none;
}



