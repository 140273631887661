@media (max-width: 768px) {
  .unlogged--bar-top-component{
    grid-template-columns: 10px 230px auto 40px 10px;
  }

  .unlogged--bar-top-component > .cl-event-login{
    display: none;
  }

  .unlogged--bar-top-component > .cl-event-register{
    display: none;
  }

  .unlogged--bar-top-component > .cl-event-dropdown{
    display: block;
  }
}