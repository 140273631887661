/* POPUP themes */
.popup-component-message{
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.55);
  z-index: 20;
  grid-template-columns: minmax(10px, auto) minmax(auto, 450px) minmax(10px, auto);
  grid-template-rows: auto min-content auto;
}

.popup-component-message-active{
  display: grid;
}
/* Finish popup base */

/* 1. Popup confirm cancel theme */
.popup-component-message > .popup-message-confirm-cancel-theme{
  grid-column-start: 2;
  grid-row-start: 2;
  row-gap: 30px;
  position: relative;
  word-break: break-all;
}

.popup-component-message > .popup-message-confirm-cancel-theme > .fr-title{
  font-size: 30px;
  padding-top: 20px;
}

.popup-component-message > .popup-message-confirm-cancel-theme
> .cancel-btn-top-right{
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: var(--close-icon-string);
}

.popup-component-message > .popup-message-confirm-cancel-theme
> .fr-events{
  grid-template-columns: auto min-content;
  column-gap: 20px;
}

.popup-component-message > .popup-message-confirm-cancel-theme
> .fr-events-one{

}

.popup-component-message > .popup-message-confirm-cancel-theme
> .fr-events > .cl > .btn{
  padding: 10px 20px;
  border-radius: 100px;
}

.popup-component-message > .popup-message-confirm-cancel-theme
> .fr-events > .cl > .btn-delete{
  background-color: var(--red-color);
  color: var(--white-color);
  white-space: nowrap;
}

/* 2. Popup Ok Cancel */
.popup-component-message > .popup-message-ok-cancel-theme{
  grid-column-start: 2;
  grid-row-start: 2;
  row-gap: 30px;
  position: relative;
  word-break: break-all;
}

.popup-component-message > .popup-message-ok-cancel-theme > .fr-title{
  font-size: 30px;
  padding-top: 20px;
}

.popup-component-message > .popup-message-ok-cancel-theme
> .cancel-btn-top-right{
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: var(--close-icon-string);
}

.popup-component-message > .popup-message-ok-cancel-theme
> .fr-events{
}

.popup-component-message > .popup-message-ok-cancel-theme
> .fr-events > .btn{
  padding: 10px 50px;
  border-radius: 100px;
}

.popup-component-message > .popup-message-ok-cancel-theme
> .fr-events > .btn-delete{
  background-color: var(--violet-color);
  color: var(--white-color);
  white-space: nowrap;
}

/* 3. Popup Input Ok */
.popup-component-message > .popup-message-input-ok-theme{
  grid-column-start: 2;
  grid-row-start: 2;
  position: relative;
  word-break: break-all;
}

.popup-component-message > .popup-message-input-ok-theme > .fr-image{
  height: 100px;
  background-image: var(--email-icon-string);
}

.popup-component-message > .popup-message-input-ok-theme > .fr-title{
  font-size: 30px;
  padding: 20px 0 0 0;
}

.popup-component-message > .popup-message-input-ok-theme
> .cancel-btn-top-right{
  width: 10px;
  height: 10px;
  position: absolute;
  right: 15px;
  top: 15px;
  background-image: var(--close-icon-string);
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-input{
  padding: 25px 0 0 0;
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-input > .input-base{
  width: 100%;
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-input > .input-invalid{
  border: 2px solid var(--light-red-color);
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-events{
  padding: 20px 0 0 0;
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-events > .btn{
  padding: 10px 50px;
  border-radius: 100px;
}

.popup-component-message > .popup-message-input-ok-theme
> .fr-events > .btn-main-event{
  background-color: var(--violet-color);
  color: var(--white-color);
  white-space: nowrap;
}