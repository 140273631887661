.unlogged--bar-top-dropdown-component{
  width: 100%;
  height: 100%;
  display: none;
}

.unlogged--bar-top-dropdown-component > .fr-content{
  width: 100%;
  height: 100%;
  grid-template-rows: 5px 5px 5px;
  row-gap: 5px;
}


.unlogged--bar-top-dropdown-component-active > .fr-content{
  position: relative;
  justify-items: center;
  align-items: center;
}

.unlogged--bar-top-dropdown-component > .fr-content
> .item{
  background-color: var(--light-black-color);
  border-radius: 100px;
  height: 100%;
  width: 100%;
}

.unlogged--bar-top-dropdown-component-active > .fr-content
> .item{
  position: absolute;
  height: 5px;
}

.unlogged--bar-top-dropdown-component-active > .fr-content
> .item-top{
  transform: rotate(45deg);
  transition-duration: 100ms;
}

.unlogged--bar-top-dropdown-component-active > .fr-content
> .item-center{
  display: none;
}

.unlogged--bar-top-dropdown-component-active > .fr-content
> .item-bottom{
  transform: rotate(-45deg);
  transition-duration: 100ms;
}

.unlogged--bar-top-dropdown-component > .fr-drop{
  width: 100%;
  position: fixed;
  top: var(--unlogged-bar-top-height);
  left: 0;
  background: var(--white-color);
  display: none;
  z-index: var(--index-one);
}

.unlogged--bar-top-dropdown-component > .fr-drop
> .btn{
  display: block;
  width: 100%;
  height: 100%;
  padding: 10px 0;
  border-radius: 10px;
  text-decoration: none;
  color: var(--light-black-color);
}

.unlogged--bar-top-dropdown-component > .fr-drop
> .btn-register{
  background-color: var(--violet-color);
  color: var(--white-color);
}

.unlogged--bar-top-dropdown-component-active > .fr-drop{
  display: block;
}
