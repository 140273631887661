.island{
  border-radius: var(--island-br);
  background-color: var(--island-bg);
  padding: var(--island-pd);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.04);
}

.shape-draw-taxonomy > .fr-bg-loading{
  display: none;
  width: 100%;
  height: 100%;
  position: absolute;
  --local-column: calc(100% / 5);
  --local-row: calc(100% / 5);
  grid-template-columns: var(--local-column) var(--local-column) var(--local-column);
  grid-template-rows: var(--local-row) var(--local-row) var(--local-row);
}

.shape-draw-taxonomy > .fr-bg-loading
> .fr-loading{
  grid-column-start: 3;
  grid-row-start: 3;
}

.shape-draw-taxonomy-loading > .fr-bg-loading{
  display: grid;
  z-index: 1;
}

.red-btn-1{
  background-color: var(--red-1);
  color: var(--white-1);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.red-btn-1:active{
  scale: 0.95;
}
.violet-btn-1{
  background-color: var(--violet-1);
  color: var(--white-1);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.violet-btn-1:active{
  scale: 0.95;
}
.yellow-btn-1{
  background-color: var(--yellow-1);
  color: var(--white-1);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.yellow-btn-1:active{
  scale: 0.95;
}

.green-btn-1{
  background-color: var(--green-1);
  color: var(--white-1);
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  cursor: pointer;
}
.green-btn-1:active{
  scale: 0.95;
}

.label{
  border-style: solid;
  border-width: 1px;
  border-radius: 100px;
  padding: 2px;
  text-align: center;
  font-size: 13px;
  font-family: 'RobotoMedium', sans-serif;
}

.green-label{
  --border-color: rgba(46, 95, 88, 1);
  --background-color: rgba(21, 38, 39, 1);
  --lightness-color: rgba(104, 226, 197, 1);
  border-color: var(--border-color);
  background-color: var(--background-color);
  color: var(--lightness-color);
}

.yellow-label{
  color: #fac909;
  background-color: #38321c;
  border-color: #725f20;
}