.unlogged--confirmation-user-component{
  height: calc(100vh);
  grid-template-rows: var(--unlogged-bar-top-height) calc(100vh - var(--unlogged-bar-top-height));
  background: linear-gradient(180deg,hsla(0,0%,100%,0), var(--light2-yellow-color) 80%);
}

.unlogged--confirmation-user-component > .fr-bottom{
  grid-template-columns: 0.5fr 2fr 0.5fr;
  grid-template-rows: 0.5fr 4fr 0.5fr;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content{
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.06);
  grid-column: 2;
  grid-row: 2;
  background-color: var(--white-color);
  background-image: var(--unlogged-lines-icon-string);
  border-radius: 15px;
  background-position: 0 10%;
  grid-template-rows: 1fr auto 1fr;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form{
  grid-row: 2;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .title{
  font-size: 50px;
  padding: 0 0 24px 0;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .title > .title-first{
  color: var(--light-black-color);
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .title > .title-second{
  background: linear-gradient(90.72deg, #FFC107 30%, #4A2EF1 92.86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .text{
  color: var(--medium-grey-color);
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .text > .text-first{
  padding: 0 0 10px 0;
}

.unlogged--confirmation-user-component > .fr-bottom
> .fr-content > .fr-text-and-form > .text .email-higlight{
  color: var(--yellow-color);
}