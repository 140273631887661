@media (max-width: 768px) {
  .ui--surf-cn--base-component > .fr-content{
    grid-template-columns: 100vw;
  }

  .ui--surf-cn--base-component > .fr-content > .cl-left{
    position: fixed;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    z-index: var(--index-two);
    display: none;
  }

  .ui--surf-cn--base-component > .fr-content > .cl-left > .fr-mobile-bg{
    display: block;
  }

  .ui--surf-cn--base-component-mobile > .fr-content > .cl-left{
    display: block;
  }
}