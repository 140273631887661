@media (max-width: 768px) {
  .unlogged--registrations-new-component > .fr-bottom
  > .fr-content > .fr-text-and-form{
    grid-template-columns: 1fr;
  }

  .unlogged--registrations-new-component > .fr-bottom{
    grid-template-columns: 5% 90% 5%;
    grid-template-rows: 0.5fr 4fr 0.5fr;
  }

  .unlogged--registrations-new-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-left{
    padding: 30px 10px 10px 10px;
  }

  .unlogged--registrations-new-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-left
  > .title{
    font-size: 36px;
    text-align: center;
  }

  .unlogged--registrations-new-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-left
  > .text{
    font-size: 16px;
    text-align: center;
  }

  .unlogged--registrations-new-component > .fr-bottom
  > .fr-content > .fr-text-and-form > .cl-right{
    padding: 10px 30px 30px 30px;
  }
}