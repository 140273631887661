.united--notify-upload-component{
  grid-template-columns: min-content auto min-content;
  width: 300px;
  column-gap: 10px;
  border-radius: 10px;
  padding: 12px 10px 10px 10px;
  color: var(--light-black-color);
  display: none;
  z-index: 30;
  box-shadow: var(--box-shadow-little-darker3);
  margin: 0 20px 20px 0;
}

.united--notify-upload-component-active{
  display: grid;
}

.united--notify-upload-component > .cl-left > .icon{
  position: relative;
}

.united--notify-upload-component > .cl-left
> .icon > .fr-percents{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: var(--medium-grey-color);
}

.united--notify-upload-component > .cl-center{
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.united--notify-upload-component > .cl-center > .title{
  padding-bottom: 10px;
}

.united--notify-upload-component > .cl-right > .btn{
  width: 15px;
  height: 15px;
  background-image: var(--close-icon-string);
  display: none;
}

.united--notify-upload-component-uploaded > .cl-right > .btn{
  display: block;
}
