.united--notify-component{
  grid-template-columns: 20px auto 20px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  min-width: 300px;
  max-width: 350px;
  column-gap: 10px;
  border-radius: 10px;
  padding: 15px 10px;
  color: var(--light-black-color);
  display: none;
  z-index: 30;
  box-shadow: var(--box-shadow-little-darker3);
}

.united--notify-component-active{
  display: grid;
}

.united--notify-component > .cl-left > .icon{
  background-image: var(--done3-icon);
  height: 15px;
  width: 15px;
}

.united--notify-component > .cl-center{
  font-size: 14px;
}

.united--notify-component > .cl-center > .title{
  padding-bottom: 10px;
}

.united--notify-component > .cl-right > .btn{
  width: 15px;
  height: 15px;
  background-image: var(--close-icon-string);
}
