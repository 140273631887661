.united--notify-list-item-component{
  grid-template-columns: min-content auto min-content;
  column-gap: 10px;
  padding: 15px 12px;
  background-color: var(--white-color);
  box-shadow: var(--box-shadow-little);
}

.united--notify-list-item-component:hover{
  scale: 1.01;
}

.united--notify-list-item-component
> .cl-left > .image{
  width: 18px;
  height: 18px;
}

.united--notify-list-item-component
> .cl-left > .reply{
  background-image: var(--reply);
}

.united--notify-list-item-component
> .cl-left > .uploaded-file{
  background-image: var(--uploaded-file);
  background-size: 80%;
}

.united--notify-list-item-component
> .cl-left > .inreply{
  background-image: var(--inreply);
  background-size: 90%;
}

.united--notify-list-item-component
> .cl-left > .removed-reply{
  background-image: var(--removed-reply);
}

.united--notify-list-item-component
> .cl-left > .updated-name{
  background-image: var(--updated-name);
}

.united--notify-list-item-component
> .cl-left > .invitation{
  background-image: var(--invitation);
}

.united--notify-list-item-component
> .cl-left > .new-version{
  background-image: var(--new-version);
}

.united--notify-list-item-component
> .cl-left > .big-file{
  background-image: var(--big-file);
}

.united--notify-list-item-component
> .cl-left > .system{
  background-image: var(--settings-icon-string);
  background-size: 85%;
}

.united--notify-list-item-component
> .cl-left > .mention{
  background-image: var(--mention);
}

.united--notify-list-item-component
> .cl-left > .moved{
  background-image: var(--moved);
}

.united--notify-list-item-component
> .cl-left > .changes-requested{
  background-image: var(--changes-requested);
}

.united--notify-list-item-component
> .cl-left > .approved{
  background-image: var(--approved);
}

.united--notify-list-item-component > .cl-left >
.profile-letters{
  width: 35px;
  height: 35px;
  background-color: var(--violet-color);
  color: var(--white-color);
  border-radius: 100px;
  line-height: 2.5;
  font-size: 14px;
}

.united--notify-list-item-component > .cl-center >
.title{
  font-size: 15px;
  padding: 0 0 5px 0;
  color: var(--light-black-color);
}

.united--notify-list-item-component > .cl-center >
.text{
  font-size: 12px;
  color: var(--medium-grey-color);
}

.united--notify-list-item-component > .cl-center >
.content{
  padding: 5px 0 0 0;
  font-size: 14px;
  color: var(--light-black-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

.united--notify-list-item-component > .cl-center >
.content > .content-line{
  grid-template-columns: auto min-content auto;
  column-gap: 5px;
}

.united--notify-list-item-component > .cl-center >
.content > .content-line > .cl-name{
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--violet-color);
}

.united--notify-list-item-component > .cl-center >
.content > .event{
  color: var(--violet-color);
}

.united--notify-list-item-component > .cl-right >
.close{
  width: 18px;
  height: 18px;
  background-image: var(--done2-icon-string);
}

.united--notify-list-item-component > .cl-right >
.close:hover{
  background-image: var(--done7-icon-string);
}

