@import url('modules.css');
@import url('constants.css');
@import url('images.css');
@import url('popups.css');
@import url('popups_mobile.css');
@import url('../../../components/ui/united_cn/navbar_component/navbar_component.css');
@import url('../../../components/ui/united_cn/navbar_component/navbar_mobile_component.css');
@import url('../../../components/ui/united_cn/navbar_processing_dropdown_component/navbar_processing_dropdown_component.css');
@import url('../../../components/ui/united_cn/navbar_new_dropdown_component/navbar_new_dropdown_component.css');

@import url('../../../components/ui/united_cn/navbar_notify_dropdown_component/navbar_notify_dropdown_component.css');
@import url('../../../components/ui/united_cn/navbar_notify_dropdown_component/navbar_notify_dropdown_mobile_component.css');

@import url('../../../components/ui/united_cn/notify_list_item_component/notify_list_item_component.css');

@import url('../../../components/ui/united_cn/navbar_upload_dropdown_component/navbar_upload_dropdown_component.css');
@import url('../../../components/ui/united_cn/navbar_upload_dropdown_component/navbar_upload_dropdown_mobile_component.css');

@import url('../../../components/ui/united_cn/navbar_user_dropdown_component/navbar_user_dropdown_component.css');
@import url('../../../components/ui/united_cn/navbar_user_dropdown_component/navbar_user_dropdown_mobile_component.css');

@import url('../../../components/ui/surf_cn/surf_component/surf_component.css');
@import url('../../../components/ui/surf_cn/surf_component/surf_mobile_component.css');

@import url('../../../components/ui/surf_cn/base_component/base_component.css');
@import url('../../../components/ui/surf_cn/base_component/base_mobile_component.css');

@import url('../../../components/ui/united_cn/notify_component/notify_component.css');
@import url('../../../components/ui/united_cn/notify_component/notify_mobile_component.css');

@import url('../../../components/ui/united_cn/notify_upload_component/notify_upload_component.css');
@import url('../../../components/ui/united_cn/notify_upload_component/notify_upload_mobile_component.css');

@import url('../../../components/ui/united_cn/notify_dark_component/notify_dark_component.css');

@font-face {font-family: 'RobotoRegular'; src: url('../../fonts/RobotoRegular.woff2') format('woff2'); font-weight: 400; font-style: normal; font-display: swap; }
@font-face {font-family: 'RobotoMedium'; src: url('../../fonts/RobotoMedium.woff2') format('woff2'); font-weight: 500; font-style: normal; font-display: swap; }
@font-face {font-family: 'RobotoMediumItalic'; src: url('../../fonts/RobotoMediumItalic.woff2') format('woff2'); font-weight: 500; font-style: italic; font-display: swap; }
@font-face {font-family: 'RobotoBold'; src: url('../../fonts/RobotoBold.woff2') format('woff2'); font-weight: 700; font-style: normal; font-display: swap; }
@font-face {font-family: 'RobotoBlack'; src: url('../../fonts/RobotoBlack.woff2') format('woff2');   font-weight: 900; font-style: normal; font-display: swap; }

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  background: #F8F8F8;
  font-family: 'RobotoRegular', sans-serif;
}

html, body{
  height: 100vh;
}

button.btn{
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
}
input.btn{
  cursor: pointer;
  background: none;
  border: none;
  display: inline-block;
}

input.input-btn{
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  background: var(--violet-color);
  color: var(--white-color);
}

div.btn-base{
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  background: var(--violet-color);
  color: var(--white-color);
}

div.btn-base2{
  padding: 10px 20px;
  border: none;
}

div.active-scaled:active{
  scale: 0.97;
}

div.btn-base-unrounded{
  border-radius: 5px;
  padding: 10px 20px;
  border: none;
  background: var(--yellow-color);
  color: var(--white-color);
  display: inline-flex;
}

div.btn-underline{
  text-decoration: underline;
}

div.btn-underline:hover{
  text-underline-offset: 2px;
}

.bg-violet{
  background-color: var(--violet-color);
}

div.btn-base2-violet{
  background-color: var(--violet-color);
  color: var(--white-color);
}

div.btn-base2-violet:hover{
  outline:2px solid var(--light6-violet-color);
}

div.btn-base-unrounded:hover{
  outline: 2px solid var(--yellow3-color);
}

label.btn-base{
  border-radius: 100px;
  padding: 10px 20px;
  border: none;
  background: var(--violet-color);
  color: var(--white-color);
}

input.txt{
  background: none;
  border: none;
  display: inline-block;
  outline: none;
}

input.text-base, input.input-txt{
  border: none;
  display: inline-block;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid var(--medium3-grey-color);
  background: var(--white-color);
}
input.input-base{
  background: none;
  border: none;
  display: inline-block;
  outline: none;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid var(--light12-grey-color);
  background: var(--white-color);
}

/* disbale input */
input:disabled{
  background: var(--light-grey-color);
}

.btn-edit2{
  border: none;
  background-color: none;
  background-image: var(--edit-2-blue);
  display: inline-block;
  outline: none;
  cursor: pointer;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.btn-edit2:active{
  scale: 0.96;
}


textarea.txt{
  background: none;
  border: none;
  display: inline-block;
  outline: none;
}
.ab{
  position: absolute;
  top: 0;
  left: 0;
}
.re{
  position: relative;
}
.cp{
  cursor: pointer;
}
.no-dec{
  text-decoration: none;
}

.br-5{
  border-radius: 5px;
}

/* GRID */
.gr{ display: grid }
.fl{ display: flex }

.fl-col{ flex-direction: column }
.fl-row{ flex-direction: row }

.align-c-s{align-content: start;}
.align-c-c{align-content: center;}
.align-c-e{align-content: end;}

.align-i-s{align-items: start;}
.align-i-c{align-items: center;}
.align-i-e{align-items: end;}

.just-c-s{justify-content: start;}
.just-c-c{justify-content: center;}
.just-c-e{justify-content: end;}

.just-i-s{justify-items: start;}
.just-i-c{justify-items: center;}
.just-i-e{justify-items: end;}

.ml-5  {margin-left: 5px  }
.ml-10 {margin-left: 10px }
.ml-20 {margin-left: 20px }
.ml-30 {margin-left: 30px }
.ml-40 {margin-left: 40px }

.mr-5  {margin-right: 5px  }
.mr-10 {margin-right: 10px }
.mr-20 {margin-right: 20px }
.mr-30 {margin-right: 30px }
.mr-40 {margin-right: 40px }

.mt-5  {margin-top: 5px  }
.mt-10 {margin-top: 10px }
.mt-20 {margin-top: 20px }
.mt-30 {margin-top: 30px }
.mt-40 {margin-top: 40px }

.mb-5  {margin-bottom: 5px  }
.mb-10 {margin-bottom: 10px }
.mb-20 {margin-bottom: 20px }
.mb-30 {margin-bottom: 30px }
.mb-40 {margin-bottom: 40px }

.no-sel{ user-select: none }

/* FONT */
.fw-400{
  font-family: 'RobotoRegular', sans-serif;
}

.fw-500{
  font-family: 'RobotoMedium', sans-serif;
}
.fw-500-i{
  font-family: 'RobotoMediumItalic', sans-serif;
}
.fw-700{
  font-family: 'RobotoBold', sans-serif;
}

.fw-900{
  font-family: 'RobotoBlack', sans-serif;
}

.fs-i{
  font-family: 'RobotoItalic', sans-serif;
}

.fs-30{ font-size: 30px }
.fs-20{ font-size: 20px }
.fs-18{ font-size: 18px }
.fs-16{ font-size: 16px }
.fs-14{ font-size: 14px }

/* BG */
.bg-no-r{
  background-repeat: no-repeat;
}
.bg-center{
  background-position: center;
}
.bg-contain{
  background-size: contain;
}

.bg-cover{
  background-size: cover;
}

/* TXT */
.no-dec{
  text-decoration: none;
}
.txt-c{
  text-align: center;
}
.txt-r{
  text-align: right;
}

.turbo-progress-bar {
  height: 3px;
  background-color: var(--violet-color);
}

.dark-island{
  color: var(--white3-color);
  border: 1px solid var(--black4-color);
  background-color: var(--black3-color);
  border-radius: 5px;
  padding: 10px;
}


.dark-input{
  color: var(--white4-color);
  border: 1px solid var(--black4-color);
  background-color: var(--black5-color);
  border-radius: 5px;
  padding: 10px;
  outline: none;
}

.dark-btn{
  color: var(--white-color);
  border: var(--border-for-dark-btn);
  background-color: var(--green2-color);
  border-radius: 6px;
  padding: 5px 16px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  display: inline-block;
}

.cancel-btn{
  text-decoration: underline;
}

.cancel-btn:hover{
  text-underline-offset: 3px;
}


.dark-btn-cancel{
  color: var(--red2-color);
  border: var(--border-for-dark-btn-cancel);
  background-color: var(--black6-color);
  border-radius: 6px;
  padding: 5px 16px;
  font-size: 14px;
  outline: none;
  cursor: pointer;
  display: inline-block;
}
.dark-btn-cancel:hover{
  color: var(--white-color);
  border: var(--border-for-dark-btn-cancel);
  background-color: var(--red3-color);
}

/* width */
.custom-scroll::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.custom-scroll::-webkit-scrollbar-track {
  background: var(--white-color);
}

/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
  background: var(--medium3-grey-color); 
  border-radius: 100px;
}

/* Handle on hover */
.custom-scroll::-webkit-scrollbar-thumb:hover {
  background: var(--medium-grey-color);
}

.pseudo__cb{
  display: block;
  width: 20px;
  height: 20px;
  cursor: pointer;
  background-image: none;
  background-color: var(--white-color);
  border: 1px solid var(--medium3-grey-color);
  border-radius: 5px;
  cursor: pointer;
}

.pseudo__cb-active{
  background-color: var(--violet-color);
  background-image: var(--check-1-white);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 11px;
}

#notify_upload{
  position: fixed;
  bottom: 0;
  right: 0;
  width: min-content;
  height: min-content;
}

.tempnotice-parent{
  position: relative;
}

.tempnotice{
  position: absolute;
  white-space: nowrap;
  bottom: -5%;
  right: 110%;
  width: min-content;
  height: min-content;
  background-color: var(--black-transparent-8);
  border-radius: 8px;
  padding: 10px;
  color: var(--white-color);
  font-size: 14px;
  z-index: var(--index-one);
}

.contributors-dropdown{
  position: absolute;
  left: 0px;
  bottom: 105%;
  width: calc(100%);
  z-index: var(--index-two);
  display: none;
  border-radius: 5px;
  box-shadow: var(--box-shadow-little-darker3);
  background-color: var(--white-color);
}

.contributors-dropdown .list-contributors{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contributors-dropdown .list-contributors .item{
  font-weight: 500;
  color: var(--light-black-color);
  font-size: 14px;
  padding: 10px 10px;
  margin: 0 0 5px 0;
  border-radius: 5px;
  grid-template-columns: 30px auto;
  column-gap: 15px;
  cursor: pointer;
  overflow: hidden;
}

.contributors-dropdown .list-contributors .item:hover{
  background: var(--light4-grey-color);
}

.contributors-dropdown .list-contributors .item .cl .ring{
  width: 30px;
  height: 30px;
  color: var(--white-color);
  background-color: var(--violet-color);
  border-radius: 100px;
  line-height: 2;
}

.contributors-dropdown--reverse{
  bottom: auto;
  top: 110%;
}

.contributors-dropdown--reverse .list-contributors{
  flex-direction: column-reverse;
}

.contributors-dropdown-active {
  display: flex;
}