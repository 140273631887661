.ui--united-cn--navbar-notify-dropdown-component{
  width: 100%;
  height: 100%;
  --local-arrow-width: 7px;
  --local-events-height: 62px;
  /* position: relative; */
}

.ui--united-cn--navbar-notify-dropdown-component > .content-fr{
  width: 100%;
  height: 100%;
  padding: 0 8px 0 16px;
}

.ui--united-cn--navbar-notify-dropdown-component > .content-fr
> .bell{
  width: 22px;
  height: 22px;
  background-image: var(--bell-icon-string);
  position: relative;
}

.ui--united-cn--navbar-notify-dropdown-component > .content-fr
> .bell > .counter{
  position: absolute;
  right: -5px;
  top: -5px;
  padding: 2px 4px;
  font-size: 12px;
  color: var(--white-color);
  background-color: var(--yellow-color);
}

.ui--united-cn--navbar-notify-dropdown-component > .dropdown-fr{
  position: fixed;
  --local-top-offset: calc(var(--navbar-height) + 5px);
  /* top: var(--local-top-offset); */
  top: 0;
  right: 0;
  z-index: 10;
  display: none;
  padding: 10px;
  /* max-height: calc(100vh - var(--local-top-offset) - 10px); */
  height: 100vh;
  width: 360px;
  border-radius: 0px;
  background-color: var(--light8-grey-color);
  box-shadow: var(--box-shadow-little-darker3)
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr{
  display: block;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title{
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .event{
  line-height: 2;
  text-decoration: underline;
  color: var(--medium-grey-color);
  white-space: nowrap;
  overflow: hidden;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .event:hover{
  text-underline-offset: 2px;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .fr-tab-events{
  grid-template-columns: min-content min-content;
  column-gap: 10px;

  width: min-content;
  background: var(--light13-grey-color);
  border-radius: 100px;
  padding: 3px;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .fr-tab-events > .item{
  grid-template-columns:  min-content min-content;
  column-gap: 5px;
  color: var(--medium-grey-color);
  padding: 6px 10px 5px 12px;
  font-size: 14px;
  position: relative;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .fr-tab-events > .item:hover{
  color: var(--light-black-color);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .fr-tab-events > .item > .counter{
  font-size: 12px;
  padding:2px 5px;
  color: var(--medium-grey-color);
  background-color: var(--light-grey-color);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-title > .fr-tab-events > .item-active{
  color: var(--light-black-color);
  background-color: var(--white-color);
  border-radius: 100px;
  box-shadow: var(--box-shadow-little);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents{
  max-height: calc(100vh - var(--local-top-offset));
  position: relative;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
.mark-as-read {
  background-image: var(--done-2-black);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
.mark-as-read:hover {
  background-image: var(--done-2-violet);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item{
  display: none;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-list{
  --local-item-height: calc(100vh - var(--local-top-offset));
  overflow-y: scroll;
  overflow-x: hidden;
  overscroll-behavior: contain;
  height: var(--local-item-height);
}


/* width */
.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-list::-webkit-scrollbar-track {
  background: var(--light8-grey-color);
}

/* Handle */
.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-list::-webkit-scrollbar-thumb {
  background: var(--medium3-grey-color); 
  border-radius: 100px;
}

/* Handle on hover */
.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-list::-webkit-scrollbar-thumb:hover {
  background: var(--medium-grey-color);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events{
  padding: 10px;
  /* grid-template-columns: min-content min-content; */
  column-gap: 20px;
  height: var(--local-events-height);
  position: absolute;
  bottom: 0;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events > .event{
  border-radius: 100px;
  box-shadow: var(--box-shadow-little-darker);
  color: var(--light-black-color);
  white-space: nowrap;
  padding: 10px;
  user-select: none;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events > .event-load{
  padding: 10px 30px;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events > .event-load:hover{
  background-color: var(--light4-grey-color);
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events > .event-cancel{
  /* aspect-ratio: 1 / 1; */
  /* padding-left: 100%; */
  padding: calc(var(--local-events-height) / 2 - 10px);
  background-color: var(--violet-color);
  background-image: var(--close2-icon-string);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 35%;
  transform: rotate(0deg);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item > .fr-events > .event-cancel:hover{
  transform: rotate(90deg);
  transition-duration: 300ms;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item-active{
  display: block;
}

.ui--united-cn--navbar-notify-dropdown-component-active > .dropdown-fr
> .fr-tab-contents > .item-archive{

}
