@media (max-width: 768px) {
  .ui--united-cn--navbar-upload-dropdown-component > .content-fr:hover{
    background-color: var(--white-color);
  }
  
  .ui--united-cn--navbar-upload-dropdown-component-active > .content-fr{
    background-color: var(--white-color);
  }

  .ui--united-cn--navbar-upload-dropdown-component
  > .dropdown-fr{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    border-radius: 0;
    align-items: center;
    justify-content: center;
    grid-template-columns: 5% min(500px, 90%) 5%;
    background: none;
  }
  
  .ui--united-cn--navbar-upload-dropdown-component
  > .dropdown-fr > .dropdown-fr-bg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--black-transparent5-color);
    border-radius: 0;
  }

  .ui--united-cn--navbar-upload-dropdown-component-active
  > .dropdown-fr{
    display: grid;
  }

  .ui--united-cn--navbar-upload-dropdown-component-active
  > .dropdown-fr > .dropdown-fr-bg{
    display: block;
  }

  .ui--united-cn--navbar-upload-dropdown-component
  > .dropdown-fr > .items{
    grid-template-columns: 100%;
    row-gap: 10px;
    padding: 13px 11px;
    z-index: var(--index-one);
    /* justify-content: center; */
    grid-column: 2;
    border-radius: 5px;
    background-color: var(--white-color);
  }

  .ui--united-cn--navbar-upload-dropdown-component
  > .content-fr > .cl-text{
    display: none;
  }

  .ui--united-cn--navbar-upload-dropdown-component
  > .content-fr > .cl-image{
    display: none;
  }

  .ui--united-cn--navbar-upload-dropdown-component
  > .content-fr > .cl-mobile{
    display: block;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 100px;
    width: 67px;
    height: 67px;
    background-color: var(--violet-color);
    background-image: var(--upload8-icon-string);
    background-size: 30%;
  }
}