.ui--surf-cn--base-component{
}

.ui--surf-cn--base-component > .fr-content{
  grid-template-columns: 300px 3.5fr;
  column-gap: 30px;
  height: calc(100vh - var(--navbar-height-size));
}

.ui--surf-cn--base-component > .fr-content > .cl-left{
  position: relative;
}

.ui--surf-cn--base-component > .fr-content > .cl-left > .fr-mobile-bg{
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--black-transparent5-color);
}

.ui--surf-cn--base-component--minimized > .fr-content{
  grid-template-columns: 77px auto;
  column-gap: 30px;
  height: calc(100vh - var(--navbar-height-size));
}
